<template>
  <svg
    version="1.1"
    viewBox="0 0 2048 824"
    x="0px"
    y="0px"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="enable-background: new 0 0 2048 824"
    fill="none"
    xml:space="preserve"
  >
    <path
      fill="currentColor"
      transform="translate(286,39)"
      d="m0 0h420l-1 9-21 89-32 136-17 74-13 55-23 98-8 38-2 4-3 1h-467l-4-4-5-13-1-5v-12l8-32v-9l-8-12 2-7 6-4 2-5-3-7-7-11 1-9 4-13 2-15 1-8-17-1-10-3-9-7-7-8-5-9 1-7 6-7 11-9 11-10 9-11 7-10 9-16 6-15 5-21 1-11-3-10-5-13-2-10 1-8 3-10 6-9 11-13 9-11 6-10 4-11v-10l-3-12v-6l3-3 8-4-1-4-8-6-10-9-4-5-3-6 1-5 7-14 3-3zm97 27-27 2-23 4-23 7-23 10-19 11-14 10-14 12-18 18-13 17-12 20-9 19-7 19-6 22-4 25-1 11v30l3 25 5 22 8 25 13 27 9 14 8 11 9 11 22 22 15 11 18 11 23 11 24 8 24 5 20 2h36l25-3 24-6 22-8 21-10 19-12 14-11 23-23 13-17 8-13 8-14 8-19 7-23 5-24 2-21v-36l-4-27-6-25-6-17-10-22-10-17-10-14-12-14-12-12-11-9-16-11-21-12-24-10-21-6-26-4-29-2z"
    />
    <path
      fill="currentColor"
      transform="translate(713,40)"
      d="m0 0h41l1 2 4 49 7 106 6 90 6 94 8 117 3 38-1 2-3 1h-38l-2-5-3-42-3-55v-8l-63 1-8 1-6 28-15 69-3 10-2 1-45 1 1-10 8-36 16-69 23-100 15-65 17-71 21-91 9-37zm18 113-3 9-9 39-12 53-9 40-12 55v5h48l2-1v-41l-2-96-1-24v-38z"
    />
    <path
      fill="currentColor"
      transform="translate(1125,40)"
      d="m0 0h106l8 13 11 21 9 25 6 24 4 24 1 15v28l-2 24-6 30-9 29-11 27-15 30-10 16-3 3-25 1h-51l-1 3-7 89-4 48-4 51-2 2h-41l-1-1 1-15 8-87 11-122 10-111 9-95 4-47 3-24zm32 75-1 1-6 65-4 55-3 34v6h56l6-3 5-8 6-13 8-24 3-13 1-9v-28l-2-18-5-19-7-19-4-6-4-1z"
    />
    <path
      fill="currentColor"
      transform="translate(370,88)"
      d="m0 0h36l25 3 21 5 19 7 16 8 14 8 13 10 14 12 11 12 12 17 9 15 10 23 5 14 6 23 2 12 1 15v26l-2 25-4 21-5 17-9 21-9 17-11 16-9 11-19 19-15 11-17 10-18 8-19 6-25 5-17 2h-40l-25-4-18-5-19-7-21-11-14-10-14-12-5-4v-2l-4-2-9-11-10-13-9-15-9-19-9-27-4-17-3-24-1-20 2-23 4-23 7-24 6-15 8-16 9-15 9-12 9-11 12-12 10-8 18-12 16-8 11-5 24-7 16-3zm14 31-19 1-17 3-21 6-25 12-12 8-10 8-12 11-9 11-12 18-8 16-7 17-5 20-3 21v35l3 21 6 22 10 23 10 16 7 9 8 10 9 9 13 10 20 12 16 7 15 5 25 5 11 1h24l21-3 17-4 20-7 17-9 17-12 13-12 7-7 8-10 10-16 8-16 5-12 6-23 3-19 1-13v-16l-4-27-7-25-9-20-7-13-12-17-12-13-10-9-15-10-23-12-18-6-19-4-32-2z"
    />
    <path
      fill="currentColor"
      transform="translate(866,40)"
      d="m0 0h138v13l-3 42-2 24-2 6-29 1h-71l-1 19-4 64-3 31h87l2 1-1 22-4 55-2 1h-87v13l-5 58-4 54-4 45-3 44-1 7-2 1h-38l-2-2v-15l6-73 18-230 12-153 3-25z"
    />
    <path
      fill="currentColor"
      transform="translate(369,142)"
      d="m0 0h43l17 3 18 6 20 10 14 10 10 9 7 7 10 14 9 16 9 23 4 16 2 15v32l-3 20-4 16-8 19-9 16-9 12-11 12-9 8-15 10-17 8-19 6-17 3-12 1h-21l-21-2-21-5-19-8-14-9-10-8-8-7-7-8-11-15-11-21-6-18-5-22-2-18v-9l3-24 5-21 6-17 10-18 10-14 11-12 11-10 15-10 17-8 17-5zm8 35-15 2-16 5-16 8-12 9-12 12-10 15-8 17-5 19-2 21v12l2 16 5 18 11 21 10 13 11 11 14 9 15 7 15 4 14 2h20l20-3 18-6 12-7 12-9 11-11 9-13 8-16 4-13 3-15 1-9v-16l-3-19-4-15-7-17-8-12-9-11-12-10-13-8-14-6-13-3-22-2z"
    />
    <path
      fill="currentColor"
      transform="translate(1040,40)"
      d="m0 0h39l1 5-2 30-8 94-18 216-6 70-5 59-2 25-1 2h-43l1-15 11-125 9-106 12-138 7-80 4-33z"
    />
    <path
      fill="currentColor"
      transform="translate(375,199)"
      d="m0 0h27l16 3 15 6 10 7 10 9 9 12 6 12 5 16 2 13v23l-4 19-5 12-6 11-9 11-10 9-15 8-13 4-16 2h-20l-19-3-13-5-11-7-9-8-10-14-8-17-4-13-2-14v-14l3-19 6-16 5-10 9-12 9-9 12-8 13-5zm6 35-11 2-12 6-8 7-7 10-5 15-1 7v14l4 16 5 10 9 9 16 8 13 3 16-1 14-5 9-7 9-10 6-13 1-4v-24l-3-12-6-11-10-10-10-6-10-3-7-1z"
    />
    <path
      fill="currentColor"
      transform="translate(380,256)"
      d="m0 0h17l10 4 7 6 4 8 2 7v10l-2 10-6 10-8 6-7 2h-18l-10-4-7-8-4-9-1-5v-11l3-10 7-9 5-4z"
    />
    <path
      fill="currentColor"
      transform="translate(1426,313)"
      d="m0 0 8 1 1 3-5 27-8 46-2 13-2 3h-7l-6-5-4 1-5 4-3 1h-10l-6-3-4-5-3-7v-19l5-14 7-9 8-7 5-2 22-2 5-22zm-24 38-8 4-8 8-4 9-1 5v8l3 7 5 3h8l8-5 6-14 4-18-2-7z"
    />
    <path
      fill="currentColor"
      transform="translate(1612,178)"
      d="m0 0h10l1 6-7 35-7 39-3 8-3 3-5-1-2-2v-3l-8 5-7 2h-12l-5-3-6-12-1-11 4-15 8-15 7-7 9-3 18-1 3-2 4-21zm-23 36-8 5-6 7-5 11v14l3 6 3 2h7l8-4 4-5 5-12 3-17-2-6-2-1z"
    />
    <path
      fill="currentColor"
      transform="translate(1657,476)"
      d="m0 0 13 1 9 3 2 4-1 18-11 55-3 8-3 2h-7l-2-4 5-20 1-6-12 5-5 1h-12l-5-4-3-6-1-11 2-12 5-12 6-9 8-8 8-4zm-2 11-7 4-7 7-5 12-1 4v12l4 6h10l9-6 6-10 4-18-1-8-2-3z"
    />
    <path
      fill="currentColor"
      transform="translate(1410,42)"
      d="m0 0h6l4 5 6 43 4 31v12l-2 1h-6l-4-5-2-20-1-4-2-1h-23l-6 10-8 14-5 5-5 1-3-2 1-5 8-18 9-19 24-44zm-1 21-6 10-8 16-2 5 10 1h9l2-1-2-22-1-9z"
    />
    <path
      fill="currentColor"
      transform="translate(1716,66)"
      d="m0 0h17l7 3 1 2-1 13-5 27-2 20-2 3-7-1-2-4v-7l-15 10-7 2-9-1-7-8-2-5v-17l3-10 7-12 8-9 10-5zm1 11-10 5-8 9-5 11v14l2 4 2 1h10l7-4 6-9 5-17 1-10-2-4z"
    />
    <path
      fill="currentColor"
      transform="translate(1489,453)"
      d="m0 0 11 1 10 5 7 8 2 4v16l-5 10-7 7-8 3-23 4-3 7-3 21-3 3-7-1-2-1v-8l6-34 6-31 4-9 4-3zm2 10-5 2-4 5-3 12-1 16 1 1h9l11-4 6-7 1-4v-9l-4-8-4-3z"
    />
    <path
      fill="currentColor"
      transform="translate(1406,477)"
      d="m0 0h13l10 4 1 4-5 22-4 29-2 5-1 1h-6l-3-5v-2l-6 1-7 6-2 1h-15l-5-3-4-8-1-13 2-12 6-12 9-10 8-5zm-1 11-9 4-5 4-7 10-3 10 1 11 4 5h9l9-6 4-6 5-16 2-9-1-7z"
    />
    <path
      fill="currentColor"
      transform="translate(1839,65)"
      d="m0 0h12l6 3 2 6-4 27-3 26-2 5-4 2-4-1-3-7-1-6h-2l-2 5-5 5-11 4-9-1-5-6-2-5v-22l4-11 6-8 7-8 9-5zm-2 12-9 3-7 6-5 7-3 11v11l2 5 1 1h8l7-3 5-5 3-4 6-18 1-8-2-5z"
    />
    <path
      fill="currentColor"
      transform="translate(1866,477)"
      d="m0 0 20 1 3 2-1 13-7 46-2 3-5 1-4-3-2-8-10 9-5 2h-14l-6-5-3-10v-9l3-13 6-12 8-10 8-5zm0 10-10 4-8 9-5 12-1 3v10l4 6 3 2 7-1 8-6 4-6 5-15 2-9-1-8-2-1z"
    />
    <path
      fill="currentColor"
      transform="translate(1940,341)"
      d="m0 0 13 1 8 4 7 8 2 6v13l-4 13-6 9-7 7-9 4-4 1h-14l-8-5-6-7-3-8v-13l4-11 7-12 8-7 5-2zm-1 10-9 6-6 10-2 7v10l3 8 4 3 3 1h7l8-5 5-6 3-6 1-5v-11l-3-7-4-4-3-1z"
    />
    <path
      fill="currentColor"
      transform="translate(1560,67)"
      d="m0 0h12l10 3 8 7 4 9v13l-4 14-7 10-8 7-7 3-13 1-9-3-5-4-6-9-2-13 4-15 8-14 7-6zm2 10-6 3-4 5-5 15v11l4 8 5 3h8l7-4 6-7 4-9v-12l-4-8-5-4-2-1z"
    />
    <path
      fill="currentColor"
      transform="translate(1895,67)"
      d="m0 0h9l10 3 8 7 4 8v16l-3 10-7 10-9 8-8 4-4 1h-10l-7-3-7-6-4-7-1-4v-17l3-9 7-10 5-6 9-4zm-1 10-6 4-6 7-4 10v13l3 7 3 3 3 1h10l6-4 6-7 3-7v-15l-3-7-4-4-3-1z"
    />
    <path
      fill="currentColor"
      transform="translate(1650,204)"
      d="m0 0h11l10 3 9 8 3 7v14l-3 12-6 9-5 6-10 6-8 2h-7l-9-3-7-7-4-7-1-5v-11l3-11 4-8 9-10 7-4zm1 10-8 6-5 8-2 8v13l4 8 6 3h7l9-6 5-8 3-10-1-12-4-6-6-4z"
    />
    <path
      fill="currentColor"
      transform="translate(1556,341)"
      d="m0 0 8 2h9l9-2h8l6 3 5 6 1 3v12l-6 32-3 8-2 1h-8l-1-1v-7l6-29v-9l-3-6-1-1h-7l-8 6-5 10-4 13-4 22-3 2h-7l-2-4 1-13 6-36 2-9z"
    />
    <path
      fill="currentColor"
      transform="translate(1754,341)"
      d="m0 0h11l8 4 4 5 1 4-1 14-5 29-3 7-2 2h-7l-3-2 1-7 5-28v-11l-2-5-7-1-5 3-6 7-5 12-4 17-2 11-3 4-7 1-3-2 3-21 6-36 3-6 8 1 7 1z"
    />
    <path
      fill="currentColor"
      transform="translate(1530,203)"
      d="m0 0 7 1 6 3 4 6 1 3v14l-4 24-3 12-3 3h-6l-2-1v-13l3-19 1-4v-13l-2-3h-9l-6 4-5 8-6 20-4 16-3 4h-7l-2-5 9-54 1-3 4-1 9 4 9-3z"
    />
    <path
      fill="currentColor"
      transform="translate(1441,204)"
      d="m0 0 5 1v9l-6 25v17l1 1 8 1 9-3 5-11 8-29 4-8 3-3h5l1 1v9l-5 26-4 24-4 4h-6l-8-5-1 3-5 3-4 1h-10l-6-3-3-7-1-4v-18l3-18 4-14z"
    />
    <path
      fill="currentColor"
      transform="translate(1741,479)"
      d="m0 0h7l1 4-6 31-4 23-4 5h-6l-6-4-4 3-7 2h-11l-5-2-4-6-1-5v-22l5-23 5-5h8l2 2-1 8-6 23v14l1 3 7 2 6-1 5-5 4-8 7-27 3-9z"
    />
    <path
      fill="currentColor"
      transform="translate(1461,341)"
      d="m0 0h11l10 4 5 6 1 4v7l-3 6-8 7-12 4-17 3 1 6 5 5 6 3 9-1 9-4h3l-2 9-5 5-5 2h-16l-8-4-6-8-3-7-1-6v-10l3-10 6-10 7-7zm0 10-7 5-3 5-1 5 4 3h9l9-3 3-3v-7l-5-5z"
    />
    <path
      fill="currentColor"
      transform="translate(1689,341)"
      d="m0 0h11l10 4 5 7 1 4v7l-3 5-13 9-6 2-9 1h-10l1 8 5 5 4 2h11l11-3 1 2-3 9-4 3-3 1h-19l-5-3-6-7-4-9v-20l3-9 8-10 8-6zm1 10-8 5-3 6v5l4 2h7l9-3 4-4v-6l-5-5z"
    />
    <path
      fill="currentColor"
      transform="translate(1546,478)"
      d="m0 0h11l8 3 7 10 1 7-3 5-9 8-10 4-8 1h-11l1 9 4 5 6 3h7l11-4h3l-1 8-3 5-3 1h-22l-8-4-5-8-2-6-1-14 3-12 5-9 7-7 8-4zm4 9-10 4-4 5-2 8 2 2h7l11-3 5-4 1-6-4-5zm-18 25 1 2z"
    />
    <path
      fill="currentColor"
      transform="translate(1388,181)"
      d="m0 0h19l21 1 3 2v5l-2 2h-34l-4 28 20 1 9 1 4 3-1 4-4 2-30 1-6 26-2 10-2 2h-8l-1-1v-10l9-54 5-19z"
    />
    <path
      fill="currentColor"
      transform="translate(1785,66)"
      d="m0 0 9 1 4 3 1 8-1 1-17-1-8 3-7 8-4 9-1 4v9l3 6 6 4 9 1 3-1h9l1 3-4 5-8 3-1 4 1 4v8l-6 7-7 3h-9l-2-6 10-3 1-4-2-1-3-10-8-7-6-9-2-6 1-12 4-10 8-11 9-8 10-4z"
    />
    <path
      fill="currentColor"
      transform="translate(1858,339)"
      d="m0 0 9 2 2 2 4 25 2 15h2l10-17 10-18 5-6 4-2 5 1-1 8-6 10-9 19-14 24-5 5-7-1-3-5-5-23-4-26v-9z"
    />
    <path
      fill="currentColor"
      transform="translate(1644,340)"
      d="m0 0 11 1 5 3 2 5v5l-8-2h-11l-8 4-6 8-4 9v14l5 6 9 3 7-1 8-2 1 1v6l-7 6-5 1h-14l-7-2-8-9-3-9v-11l2-9 7-13 9-9 8-4z"
    />
    <path
      fill="currentColor"
      transform="translate(1633,66)"
      d="m0 0 13 1 4 2 3 6v4l-11-1h-11l-6 3-7 8-3 7-1 5v8l3 9 8 4 15-1 4 4-3 5-6 3-5 1h-9l-8-2-8-7-4-8 1-22 7-14 8-9 10-5z"
    />
    <path
      fill="currentColor"
      transform="translate(1511,67)"
      d="m0 0h14l3 4v7l-2 1-13-2-6 3-1 4 3 7 4 4 6 4 6 8v10l-6 9-9 6-6 2h-8l-8-4-4-5-1-6 11 3h10l5-2 2-8-4-6-9-6-4-5-2-4v-7l5-9 7-6z"
    />
    <path
      fill="currentColor"
      transform="translate(1802,478)"
      d="m0 0h17l4 2 1 7-4 2-4-1h-10l-4 4v8l7 6 8 6 3 4 1 9-3 9-5 5-5 3-6 1h-15l-5-2-3-5 2-6 11 3h8l6-5v-7l-4-4-11-8-3-5v-10l4-8 5-5z"
    />
    <path
      fill="currentColor"
      transform="translate(1465,67)"
      d="m0 0h11l5 5 1 5-1 1-15-1-8 2 1 7 6 8 7 6 6 9 2 7-8 11-5 4-12 3-10-1-6-5-1-5 1-2h7l4 1h7l6-4 1-5-4-7-10-7-4-6-1-7 3-9 5-5 4-3z"
    />
    <path
      fill="currentColor"
      transform="translate(1600,477)"
      d="m0 0h11l4 2 2 5-1 5-15-1-6 3-1 6 3 5 11 8 4 5 1 3v9l-5 10-8 5-7 1h-14l-5-2-1-1 1-7 5-1 5 1h9l5-4 1-9-4-4-9-5-5-6-1-2v-6l5-11 8-7z"
    />
    <path
      fill="currentColor"
      transform="translate(1807,328)"
      d="m0 0h5l2 2v7l-1 3 13 4 3 3-5 3-13 2-4 16-3 14 1 8 7 8 2 3v4l-5 2h-8l-6-3-4-8v-12l5-26-1-5-5-3 1-5 8-7 4-6z"
    />
    <path
      fill="currentColor"
      transform="translate(1533,318)"
      d="m0 0h8l1 1v7l-7 37-7 36-2 6-5 1-7-1-1-4 6-29 8-40 3-12z"
    />
    <path
      fill="currentColor"
      transform="translate(1669,44)"
      d="m0 0h8l4 4-1 7-3 3-1 4-2 21-5 31-4 13-5 6-5 1-3-3 1-7 7-39 4-13 5-8h2l1-4-5-4-2-4 1-5z"
    />
    <path fill="currentColor" transform="translate(1839,341)" d="m0 0h7l2 2-2 21-8 36-3 6h-9l-1-2 1-12 7-35 3-12z" />
    <path fill="currentColor" transform="translate(1767,480)" d="m0 0h9l1 1-1 9-8 42-1 7-3 3h-7l-3-5 5-30 5-24z" />
    <path fill="currentColor" transform="translate(1826,47)" d="m0 0h28l2 2-1 6-4 3-9-2-7 1-6 4-4 2-1-5v-8z" />
    <path fill="currentColor" transform="translate(1398,453)" d="m0 0 7 1 7 9 4 11h-8l-7-6-6-10 1-4z" />
    <path fill="currentColor" transform="translate(1842,318)" d="m0 0h9l4 4v6l-8 5h-6l-3-4v-7z" />
    <path fill="currentColor" transform="translate(1773,454)" d="m0 0 7 1 2 4-1 9-1 2h-7l-4-3-1-8z" />
    <path fill="currentColor" transform="translate(1764,144)" d="m0 0" />
  </svg>
</template>
